'use client';

import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';
import AnalysisCalculator from '@/components/AnalysisCalculator';
import Auth from '@/components/Auth';
import { PaymentItem } from '@/components/Plans/PaymentItem';
import Button from '@/components/ui/Button';
import Payment from '@/components/ui/Payment';
import { ROUTES } from '@/data';
import { useTranslations } from '@/hooks';
export function useOpenClose(isShowModal) {
  const [open, setOpen] = useState(isShowModal);
  const handlers = {
    open: useCallback(() => setOpen(true), []),
    close: useCallback(() => setOpen(false), []),
    toggle: useCallback(() => setOpen(current => !current), [])
  };
  useEffect(() => {
    if (isShowModal && !open) handlers.open();
  }, [isShowModal]);
  return [open, handlers];
}
const PlanActionAnalysis = ({
  planId,
  title,
  planName,
  icon,
  color,
  disabled,
  price,
  availableAnalysis
}) => {
  const [isAnalysisCalculatorModalOpen, openCloseHandlers] = useOpenClose(false);
  const queryClient = useQueryClient();
  const t = useTranslations();
  useEffect(() => {
    if (isAnalysisCalculatorModalOpen) {
      openCloseHandlers.close();
    }
  }, []);
  const getButtonTitle = () => {
    if (disabled) {
      return t('navigation.login.required');
    }
    return t('plans.card.common.button.analyze');
  };
  return <>
      <AnalysisCalculator open={isAnalysisCalculatorModalOpen} availableAnalysis={availableAnalysis} onSuccessCallback={() => {
      queryClient.invalidateQueries({
        queryKey: ['availableAnalysis']
      });
      openCloseHandlers.close();
    }} data-sentry-element="AnalysisCalculator" data-sentry-source-file="PlanActionAnalysis.jsx" />

      <Auth login loginProps={{
      label: t('navigation.login.required')
    }} redirect={ROUTES.ANALYSIS} data-sentry-element="Auth" data-sentry-source-file="PlanActionAnalysis.jsx">
        {availableAnalysis === 0 ? <Payment className="flex justify-center w-100" plan={planId} price={price} title={title} planName={planName} mode="payment">
            <PaymentItem title={title} icon={icon} />
          </Payment> : <Button variant={color} onClick={() => openCloseHandlers.open()} disabled={disabled}>
            {getButtonTitle()}
          </Button>}
      </Auth>
    </>;
};
export default PlanActionAnalysis;