import Auth from '@/components/Auth';
import Button from '@/components/ui/Button';
import { ROUTES } from '@/data';
import { useTranslations } from '@/hooks';
const PlanActionVip = ({
  color,
  disabled
}) => {
  const t = useTranslations();
  const getButtonTitle = () => {
    if (disabled) {
      return t('navigation.login.required');
    }
    return t('plans.card.common.button.calculate_price');
  };
  return <Auth login loginProps={{
    label: t('navigation.login.required')
  }} redirect={ROUTES.VIP} data-sentry-element="Auth" data-sentry-component="PlanActionVip" data-sentry-source-file="PlanActionVip.jsx">
      <Button variant={color} className="w-full" href={ROUTES.VIP} disabled={disabled} data-sentry-element="Button" data-sentry-source-file="PlanActionVip.jsx">
        {getButtonTitle()}
      </Button>
    </Auth>;
};
export default PlanActionVip;