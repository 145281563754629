import Badge from '@/components/ui/Badge/Badge';
import { useTranslations } from '@/hooks';
import { cn } from '@/utils';
const PlansHeader = ({
  className
}) => {
  const t = useTranslations();
  const classes = cn({
    'plans-header': true,
    'flex flex-col items-center justify-end relative': true,
    'pt-16 pb-4 mb-4 md:pt-32 md:mb-10 ': true,
    [className]: className
  });
  const contentClasses = cn({
    'w-full h-full': true,
    'flex flex-col items-center justify-end': true,
    'text-center px-4 pb-4 md:pb-4 z-20': true
  });
  return <div className={classes} data-sentry-component="PlansHeader" data-sentry-source-file="PlansHeader.jsx">
      <img className="z-0 rounded-xl border-outline" src="/images/pricing/matchfy_pricing_banner.jpg" alt="Matchfy Pricing Banner" />

      <div className="overlay" />

      <div className={contentClasses}>
        <Badge variant="primary" label={t('navigation.pricing')} className="px-5 mb-3 rounded-full" data-sentry-element="Badge" data-sentry-source-file="PlansHeader.jsx" />

        <h1 className="m-0 mb-5 text-3xl sm:text-4xl md:text-5xl">
          {t('plans.header.title', {
          shouldParse: true
        })}
        </h1>

        <h2 className="font-light text-lg md:text-xl opacity-80 md:max-w-[54ch] m-0 mb-4">
          {t('plans.header.subtitle', {
          shouldParse: true
        })}
        </h2>

        <p className="font-medium text-lg md:text-xl md:max-w-[54ch] m-0 mb-4">
          {t('plans.header.text', {
          shouldParse: true
        })}
        </p>
      </div>
    </div>;
};
export default PlansHeader;