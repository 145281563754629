import Badge from '@/components/ui/Badge';
import Icon from '@/components/ui/Icon';
import SectionTitle from '@/components/ui/SectionTitle/SectionTitle';
import { useTranslations } from '@/hooks';
import { cn } from '@/utils';
const renderItem = item => {
  if (item && item.length > 0) return item;else if (item) return <Icon className="text-xl text-green" name="Check" />;else return <Icon className="text-xl text-red" name="X" />;
};
export const ComparePlans = ({
  title,
  compareTitle,
  features,
  matchfy,
  competitor
}) => {
  const classes = cn({
    table: true,
    'plan-compare': true
  });
  return <table className={classes} data-sentry-component="ComparePlans" data-sentry-source-file="ComparePlans.jsx">
      <thead>
        <tr>
          <th></th>
          <th className="text-lg text-center opacity-100">
            <Icon className="mr-1" name="Flame" data-sentry-element="Icon" data-sentry-source-file="ComparePlans.jsx" />
            {title}
          </th>
          <th className="text-center opacity-100">{compareTitle}</th>
        </tr>
      </thead>
      <tbody>
        {features.map((feature, index) => <tr key={index}>
            <td>{feature}</td>
            <td className="text-center">{renderItem(matchfy[index])}</td>
            <td className="text-center">{renderItem(competitor[index])}</td>
          </tr>)}
      </tbody>
    </table>;
};
export const CompareSection = () => {
  const t = useTranslations();
  const compareData = {
    title: 'Matchfy',
    compareTitle: 'Competitors',
    features: [t('compare.features.0.name'), t('compare.features.1.name'), t('compare.features.2.name'), t('compare.features.3.name'), t('compare.features.4.name'), t('compare.features.5.name'), t('compare.features.6.name'), t('compare.features.7.name')],
    matchfy: [t('compare.features.0.matchfy'), t('compare.features.1.matchfy'), t('compare.features.2.matchfy'), t('compare.features.3.matchfy'), t('compare.features.4.matchfy'), t('compare.features.5.matchfy'), t('compare.features.6.matchfy'), t('compare.features.7.matchfy')],
    competitor: [t('compare.features.0.competitor'), t('compare.features.1.competitor'), t('compare.features.2.competitor'), t('compare.features.3.competitor'), t('compare.features.4.competitor'), t('compare.features.5.competitor'), t('compare.features.6.competitor'), t('compare.features.7.competitor')]
  };
  return <section className="compare-section" data-sentry-component="CompareSection" data-sentry-source-file="ComparePlans.jsx">
      <div className="container max-w-5xl mx-auto">
        <SectionTitle tag="h3" title={t('compare.header.title')} subtitle={t('compare.header.subtitle')} badge={<Badge size="md" variant="primary-light" label={t('compare.header.cta')} icon="Scale" />} data-sentry-element="SectionTitle" data-sentry-source-file="ComparePlans.jsx" />

        <div className="w-full">
          <ComparePlans {...compareData} data-sentry-element="ComparePlans" data-sentry-source-file="ComparePlans.jsx" />
        </div>
      </div>
    </section>;
};
export default CompareSection;