import { PlanPriceAnalysis, PlanPriceDefault, PlanPriceVip } from './prices';
const PlanPrice = ({
  price,
  salePrice,
  save,
  layout,
  displayPrice,
  countdown,
  gemBonus,
  color,
  ...rest
}) => {
  if (!displayPrice) {
    return <div className="plan-price"></div>;
  }
  return <div className="plan-price" data-sentry-component="PlanPrice" data-sentry-source-file="PlanPrice.jsx">
      {layout === 'default' && <PlanPriceDefault color={color} price={price} salePrice={salePrice} countdown={countdown} duration="y" displayMonthly save={save} gemBonus={gemBonus} {...rest} />}

      {layout === 'vip' && <PlanPriceVip price={price} />}

      {layout === 'analysis' && <PlanPriceAnalysis price={price} {...rest} />}
    </div>;
};
export default PlanPrice;